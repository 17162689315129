@layer mantine {.m_b6d8b162 {
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  font-size: var(--text-fz, var(--mantine-font-size-md));
  line-height: var(--text-lh, var(--mantine-line-height-md));
  font-weight: normal;
  margin: 0;
  padding: 0;
  color: var(--text-color);
}

  .m_b6d8b162:where([data-truncate]) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .m_b6d8b162:where([data-truncate='start']) {
    direction: rtl;
    text-align: right;
  }

  :where([dir="rtl"]) .m_b6d8b162:where([data-truncate='start']) {
      direction: ltr;
      text-align: left;
}

  .m_b6d8b162:where([data-variant='gradient']) {
    background-image: var(--text-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .m_b6d8b162:where([data-line-clamp]) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: var(--text-line-clamp);
    -webkit-box-orient: vertical;
  }

  .m_b6d8b162:where([data-inherit]) {
    line-height: inherit;
    font-weight: inherit;
    font-size: inherit;
  }

  .m_b6d8b162:where([data-inline]) {
    line-height: 1;
  }
}