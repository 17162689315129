@layer mantine {.m_87cf2631 {
  background-color: transparent;
  cursor: pointer;
  border: 0;
  padding: 0;
  appearance: none;
  font-size: var(--mantine-font-size-md);
  text-align: left;
  text-decoration: none;
  color: inherit;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
}

  :where([dir="rtl"]) .m_87cf2631 {
    text-align: right;
}
}