/* src/plugins/delivery-management/ui/delivery-scheduler.css */
.delivery-scheduler {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  margin: 20px;
  width: max-content;
}
.days-header {
  display: grid;
  grid-template-columns: 100px repeat(7, 1fr);
  border-bottom: 1px solid #ddd;
  padding: 0.5rem;
}

.days-header > div {
  text-align: center;
  font-weight: bold;
}
.time-grid {
  display: grid;
  grid-template-columns: 100px repeat(7, 1fr);
}
.time-row {
  display: grid;
  grid-template-columns: 100px repeat(7, 1fr);
  border-bottom: 1px solid #eee;
}
.time-label {
  padding: 0.5rem;
  text-align: right;
  border-right: 1px solid #eee;
  font-size: 0.8rem;
}
.time-slot {
  padding: 0.5rem;
  border-right: 1px solid #eee;
  height: 1.5rem;
}
.time-slot:hover {
  background: #f8f8f8;
  cursor: pointer;
}

.time-block {
  background: lightblue;
  border-radius: 4px;
  padding: 4px;
  margin: 1px;
  text-align: center;
  font-size: 0.8rem;
}

.time-block-form {
  padding: 20px;
  margin: 20px;
  border: 1px solid #ddd;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.time-block-form div {
  margin-bottom: 10px;
}

.time-block-form label {
  display: block;
  margin-bottom: 5px;
}

.time-block-form input[type='number'],
.time-block-form select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 200px;
}

.time-block-form .button-container {
  display: flex;
  gap: 10px;
}

.time-block-form button {
  padding: 10px 15px;
  background-color: #5cb85c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.time-block-form button:hover {
  background-color: #4cae4c;
}
